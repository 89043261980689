import {z} from 'zod'

import {GetIndexPageQueryDataSchema} from '../landing/types'
import NavAlert from './NavAlert'
import NavLinks from './NavLinks'
import NavResources from './NavResources'

const EOSubnavPropsSchema = z.object({
  field: z.string(),
  navLinks: GetIndexPageQueryDataSchema.shape.indexPage.shape.navLinks,
  navResources: GetIndexPageQueryDataSchema.shape.indexPage.shape.navResources,
  navAlert: GetIndexPageQueryDataSchema.shape.indexPage.shape.navAlert,
})

export default function EOSubnav({
  navLinks,
  navResources,
  navAlert,
}: z.infer<typeof EOSubnavPropsSchema>) {
  return (
    <>
      {navLinks && <NavLinks navLinks={navLinks} />}
      {navResources && <NavResources navResources={navResources} />}
      {navAlert?.showAlertBeacon && <NavAlert navAlert={navAlert} />}
    </>
  )
}
